import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Helmet from 'react-helmet'
import styled from 'styled-components'
import _get from 'lodash/get'
import { breakPoints, colors, fonts } from '@constants/styles'
import OrderList from '@components/order/status/order-list'
import { Card, CardContent } from '@shared/card'
import { setOrderList } from '@redux/modules/orderStatus'
import { getOrdersHistory } from '@services/order-status'
import { announce } from '@helpers/aria-announce'
import { getURLParam } from '@helpers/general'
import { getNumbersOnly } from '@helpers/string-helper'
import { orderStatusEvent } from '@helpers/google-tag-manager'
import { decryptOrderKey } from '@helpers/getOrderByOrderKey'
import Layout from '../../components/layout'

const Title = styled.h1`
  font-size: ${fonts.txtXlarge};
  text-transform: uppercase;
  margin: 0.5rem 0 -0.5rem 0.5rem;
  color: ${colors.darkGrey};
  @media only screen and (max-width: ${breakPoints['large-min']}) {
    margin-bottom: -1rem;
  }
`
const OrderListWrapper = () => {
  const orderList = useSelector(state => state.orderStatus.orderList)
  const activeOrders = orderList.filter(orderItem => orderItem.active)
  const pastOrders = orderList.filter(orderItem => !orderItem.active)
  const [err, setErr] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    // / check browser url to load
    if (!orderList.length && !err) {
      const orderKey = getURLParam('orderKey')
      let orderId = getURLParam('orderId') || ''
      let phoneNumber = getURLParam('phoneNumber') || ''
      let zipCode = getURLParam('zipCode') || ''

      if (orderKey) {
        const decryptedOrderKey = decryptOrderKey(orderKey)
        orderId = _get(decryptedOrderKey, 'orderNumber', orderId)
        phoneNumber = getNumbersOnly(_get(decryptedOrderKey, 'phoneNumber', phoneNumber))
        zipCode = _get(decryptedOrderKey, 'zipcode', '')
      }

      if (orderId && phoneNumber) {
        getOrdersHistory(`?orderId=${orderId}&phoneNumber=${phoneNumber}&zipcode=${zipCode}`).then(data => {
          if (data?.rtgOrders) {
            dispatch(setOrderList(data?.rtgOrders || []))
            setErr(false)
            const [analyticsOrder = {}] = data?.rtgOrders?.filter(order => order?.OrderNumber === orderId)
            orderStatusEvent('retrieveOrderSuccess', analyticsOrder)
          } else {
            announce('We are unable to retrieve your order, please try again later.')
            setErr(true)
            dispatch(setOrderList([]))
          }
        })
      } else {
        announce('We are unable to retrieve your order, please try again later.')
        setErr(true)
      }
    }
  }, [orderList, err, dispatch])

  return (
    <Layout>
      <Helmet title="Order Status List - Rooms To Go" />
      <Title>Order History</Title>
      <div className="order-status">
        <div className="grid-x cell small-12 active-orders-list">
          {activeOrders.length > 0 && (
            <>
              <Card>
                <CardContent>
                  <OrderList orderList={activeOrders} heading="Active Orders" />
                </CardContent>
              </Card>
            </>
          )}
          {pastOrders.length > 0 && (
            <>
              <Card>
                <CardContent>
                  <OrderList orderList={pastOrders} heading="Past Orders" />
                </CardContent>
              </Card>
            </>
          )}
        </div>
      </div>
    </Layout>
  )
}

export default OrderListWrapper
