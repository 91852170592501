import styled from 'styled-components'
import { breakPoints, colors, fonts } from '@constants/styles'

const BaseButton = styled.button`
  &&& {
    border-radius: 5px;
    border: 2px solid;
    box-sizing: border-box;
    font-size: ${fonts.txtMedium};
    font-weight: 600;
    height: fit-content;
    margin: 0.5rem 0;
    min-width: 160px;
    padding: 10px 20px;
    text-align: center;
    text-transform: capitalize;
    width: fit-content;
    @media screen and (max-width: ${breakPoints['medium-max']}) {
      max-width: 300px;
      display: block;
      margin: 0 auto;
      width: 100%;
    }
  }
`

export const PrimaryButton = styled(BaseButton)`
  &&& {
    background-color: ${colors.primary};
    border-color: ${colors.primary};
    color: ${colors.offWhite};
  }
`

export const SecondaryButton = styled(BaseButton)`
  &&& {
    background-color: ${colors.offWhite};
    border-color: ${colors.primary};
    color: ${colors.primary};
  }
`

export const RedButton = styled(BaseButton)`
  &&& {
    background-color: ${colors.red};
    border-color: ${colors.red};
    color: ${colors.offWhite};
  }
`
