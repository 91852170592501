import React from 'react'
import { node } from 'prop-types'
import styled from 'styled-components'
import { breakPoints } from '@constants/styles'

const StyledCardHeader = styled.div`
  display: flex;
  width: 100%;

  @media print, screen and (max-width: ${breakPoints.small}) {
    padding: 5px;
  }
`

const CardHeader = ({ children }) => <StyledCardHeader> {children} </StyledCardHeader>

CardHeader.propTypes = {
  children: node,
}

export default CardHeader
