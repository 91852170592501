import React from 'react'
import { string } from 'prop-types'
import styled from 'styled-components'
import { breakPoints, fonts } from '@constants/styles'

const StyledCardHeaderNumber = styled.div`
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  background-color: black;
  color: white;
  margin-right: 20px;
  font-weight: 700;
  align-self: center;
  font-size: ${fonts.txtLarge};

  @media print, screen and (max-width: ${breakPoints.small}) {
    padding: 0px;
    margin-right: 5px;
    font-size: ${fonts.txtMediumUp};
  }
`

const HeaderNumber = ({ headerNumber = '' }) => <StyledCardHeaderNumber>{headerNumber}</StyledCardHeaderNumber>

HeaderNumber.propTypes = {
  headerNumber: string,
}

export default HeaderNumber
