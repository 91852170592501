import { Buffer } from 'buffer'
import crypto from 'crypto'
import _get from 'lodash/get'

export const getDecryptedCodes = key => {
  const decipher = crypto.createDecipheriv(
    process.env.GATSBY_FINANCE_CODE_ENCRYPTION_ALGORITHM,
    Buffer.from(process.env.GATSBY_FINANCE_CODE_ENCRYPTION_KEY),
    process.env.GATSBY_FINANCE_CODE_ENCRYPTION_IV,
  )
  const encryptedCodes = Buffer.from(key, process.env.GATSBY_FINANCE_CODE_ENCRYPTION_RADIX)
  const decryptedCodes = Buffer.concat([decipher.update(encryptedCodes), decipher.final()])
  return decryptedCodes
}

export const decryptOrderKey = key => {
  const decryptedCodes = getDecryptedCodes(key)
  const data = decryptedCodes.toString().split('|')
  const response = {
    orderNumber: _get(data, 0),
    zipcode: _get(data, 1),
    division: _get(data, 2),
    phoneNumber: _get(data, 3),
  }
  return response
}
