import React from 'react'
import { bool, node } from 'prop-types'
import styled from 'styled-components'
import { breakPoints } from '@constants/styles'

const StyledCard = styled.div`
  display: flex;
  flex-flow: column nowrap;
  background-color: white;
  padding: 20px;
  margin: 10px;
  box-shadow: ${props => (props.withShadow ? `0 2px 5px rgba(0, 0, 0, 0.2)` : `none`)};
  width: 100%;

  @media print, screen and (max-width: ${breakPoints.small}) {
    padding: 5px;
    margin: 5px;
  }
`

const Card = ({ children, withShadow }) => <StyledCard withShadow={withShadow}> {children} </StyledCard>

Card.propTypes = {
  children: node,
  withShadow: bool,
}

export default Card
