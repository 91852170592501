import React from 'react'
import { array, string } from 'prop-types'
import styled from 'styled-components'
import { breakPoints, colors, fonts } from '@constants/styles'
import OrderListItem from '../order-list-item'

const OrderListWrapper = styled.div`
  width: 100%;
  padding: 1rem 1rem 0 1rem;
  background: #fff;
  @media only screen and (max-width: ${breakPoints['large-min']}) {
    padding: 0;
  }
`

const Title = styled.h2`
  font-size: ${fonts.txtMediumUp};
  font-weight: 600;
  width: 100%;
  border-color: ${colors.darkGrey};
  padding-bottom: 1.5rem;
  text-transform: uppercase;
  @media only screen and (max-width: ${breakPoints['large-min']}) {
    font-size: ${fonts.txtMedium};
    padding: 0 1rem 1rem;
    font-weight: bold;
  }
`

const TopHeadings = styled.div`
  padding: 0 8px;
  @media only screen and (max-width: ${breakPoints['large-min']}) {
    display: none;
  }
`

const Heading = styled.div`
  font-size: ${fonts.txtSmall};
  text-align: center;
  font-weight: 600;
  @media only screen and (min-width: ${breakPoints['large-min']}) {
    font-size: ${fonts.txtMedium};
    text-transform: uppercase;
    &.large-2 {
      width: 14%;
      padding: 0 0.5rem;
    }
  }
`

const OrderListItemWrapper = styled.div`
  padding: 0.5rem;
`

const OrderList = ({ orderList, heading }) => (
  <OrderListWrapper>
    {orderList && orderList.length > 0 && (
      <div className="grid-x cell small-12">
        <Title>{heading}</Title>
        <TopHeadings className="grid-x cell small-12">
          <Heading className="cell large-2">Order #</Heading>
          <Heading className="cell large-2">Order Date</Heading>
          <Heading className="cell large-2">Total</Heading>
          <Heading className="cell large-2">Balance</Heading>
          <Heading className="cell large-2">Delivery Date</Heading>
          <Heading className="cell large-2">Status</Heading>
          <Heading className="cell large-2" />
        </TopHeadings>
        <OrderListItemWrapper className="grid-x cell small-12">
          {orderList.map(order => (
            <OrderListItem order={order} key={order.OrderNumber + order.OrderDate} />
          ))}
        </OrderListItemWrapper>
      </div>
    )}
  </OrderListWrapper>
)

OrderList.propTypes = {
  heading: string,
  orderList: array,
}

export default OrderList
