import React from 'react'
import { node } from 'prop-types'
import styled from 'styled-components'
import { breakPoints, fonts } from '@constants/styles'

const StyledHeaderContent = styled.div`
  display: flex;
  flex-flow: column nowrap;
  font-size: ${fonts.txtMediumUp};
  font-weight: 700;

  @media print, screen and (max-width: ${breakPoints.small}) {
    padding: 5px;
    margin: 5px;
  }
`

const HeaderContent = ({ children }) => <StyledHeaderContent> {children} </StyledHeaderContent>

HeaderContent.propTypes = {
  children: node,
}

export default HeaderContent
