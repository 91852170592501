import React from 'react'
import { node } from 'prop-types'
import styled from 'styled-components'
import { breakPoints } from '@constants/styles'

const StyledCardContent = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin: 10px 10px 10px 60px;

  @media print, screen and (max-width: ${breakPoints.small}) {
    padding: 5px;
    margin: 5px;
  }
`

const CardContent = ({ children }) => <StyledCardContent> {children} </StyledCardContent>

CardContent.propTypes = {
  children: node,
}

export default CardContent
