import React from 'react'
import { useDispatch } from 'react-redux'
import { shape, string } from 'prop-types'
import { navigate } from 'gatsby'
import { getDateCalendarFormat } from '@helpers/date'
import { currencyFormatUS } from '@helpers/string-helper'
import { setOrder, setOrderNumber } from '@redux/modules/orderStatus'
import styled from 'styled-components'
import { breakPoints, colors, fonts } from '@constants/styles'
import { PrimaryButton, RedButton } from '@shared/button-types'
import { orderStatusEvent } from '@helpers/google-tag-manager'

const OrderGroup = styled.div`
  font-size: ${fonts.txtXsmall};
  padding: 0.5rem 0.5rem 0 0.5rem;
  margin-bottom: 0;
  display: flex;
  @media only screen and (min-width: ${breakPoints['large-min']}) {
    border: 1px solid ${colors.grey};
    padding: 0.5rem 0;
    align-items: center;
    font-size: ${fonts.txtMedium};
    margin-bottom: 1rem;
  }
`

const DesktopView = styled.div`
  display: none;
  @media only screen and (min-width: ${breakPoints['large-min']}) {
    display: flex;
    .cell {
      margin: auto 0;
    }
  }
`

const MobileView = styled.div`
  display: none;
  @media only screen and (max-width: ${breakPoints['large-min']}) {
    display: flex;
    .cell {
      margin-bottom: 1rem;
    }
    hr {
      border-top: 1px solid ${colors.grey};
    }
  }
`

const Heading = styled.div`
  font-size: ${fonts.txtSmall};
  text-transform: uppercase;
  font-weight: bold;
`

const OrderValue = styled.div`
  text-align: center;
  @media only screen and (min-width: ${breakPoints['large-min']}) {
    &.large-2 {
      width: 14%;
    }
  }
`

const OrderListItem = React.memo(({ order }) => {
  const dispatch = useDispatch()
  const {
    Balance = '0',
    DeliveryDate = 'someString',
    OrderDate = '0',
    OrderNumber = '0',
    OrderTotal = '0',
    Status = 'someString',
  } = order

  const viewOrderDetails = () => {
    dispatch(setOrder(order))
    dispatch(setOrderNumber(OrderNumber))
    orderStatusEvent('viewOrderDetails', order)
    // TODO: navigation need to add code to track ga data
    navigate(`/order/details`)
  }

  const orderDateFormat = getDateCalendarFormat(OrderDate)
  const OrderTotalFormat = currencyFormatUS(OrderTotal)
  const BalanceFormat = currencyFormatUS(Balance)
  const DeliveryDateFormat = DeliveryDate !== 'someString' ? getDateCalendarFormat(DeliveryDate) : 'Unscheduled'
  const renderDetailsButton = () => {
    if (order.showPayonline) {
      return (
        <RedButton type="button" onClick={viewOrderDetails}>
          Balance Due
        </RedButton>
      )
    }
    return (
      <PrimaryButton type="button" onClick={viewOrderDetails}>
        View Details
      </PrimaryButton>
    )
  }

  return (
    <>
      {order && (
        <>
          <OrderGroup className="large-12 small-12" key={OrderNumber}>
            <DesktopView className="grid-x cell large-12">
              <OrderValue className="cell small-6 large-2"> {OrderNumber}</OrderValue>
              <OrderValue className="cell small-6 large-2"> {orderDateFormat}</OrderValue>
              <OrderValue className="cell small-6 large-2"> {OrderTotalFormat}</OrderValue>
              <OrderValue className="cell small-6 large-2"> {BalanceFormat}</OrderValue>
              <OrderValue className="cell small-6 large-2">{DeliveryDateFormat}</OrderValue>
              <OrderValue className="cell small-6 large-2"> {Status}</OrderValue>
              <OrderValue className="cell small-12 large-2">{renderDetailsButton()}</OrderValue>
            </DesktopView>
            <MobileView className="grid-x cell small-12">
              <div className="grid-x cell small-12">
                <div className="small-4">
                  <Heading>Order #</Heading>
                  <div>{OrderNumber}</div>
                </div>
                <div className="small-4">
                  <Heading>Order Placed</Heading>
                  <div>{orderDateFormat}</div>
                </div>
                <div className="small-4">
                  <Heading>Total</Heading>
                  <div>{OrderTotalFormat}</div>
                </div>
              </div>
              <div className="grid-x cell small-12">
                <div className="small-4">
                  <Heading>Balance</Heading>
                  <div>{BalanceFormat}</div>
                </div>
                <div className="small-4">
                  <Heading>Delivery Date</Heading>
                  <div>{DeliveryDateFormat}</div>
                </div>
                <div className="small-4">
                  <Heading>Status</Heading>
                  <div>{Status}</div>
                </div>
              </div>
              <OrderValue className="small-12 large-2">{renderDetailsButton()}</OrderValue>
            </MobileView>
          </OrderGroup>
          <MobileView className="small-12">
            <hr />
          </MobileView>
        </>
      )}
    </>
  )
})

OrderListItem.displayName = 'OrderListItem'

OrderListItem.propTypes = {
  order: shape({
    Balance: string,
    DeliveryDate: string,
    OrderDate: string,
    OrderNumber: string,
    OrderTotal: string,
    Status: string,
  }).isRequired,
}

export default OrderListItem
